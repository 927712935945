<template>
  <div>
    <PageHeader title="Panden zoeken">
      <div slot="buttons" class="tw-font-normal tw-text-sm tw-flex tw-flex-wrap tw-justify-end tw-gap-1">
        <router-link
          :to="{ name: 'ChecklistOverview', query: checklistQuery }"
          title="Checklisten"
          class="action tw-px-2 tw-bg-warning"
        >
          <i class="far fa-eye" /> Checklisten
        </router-link>
      </div>
    </PageHeader>
    <div class="tw-px-2.5 tw-pt-5 tw-pb-16 tw-mx-auto">
      <Tabs :tabs="tabs" @tabSelected="showMap">
        <template #tab-button="{ tab }">
          {{ tab.name }}
          <div
            v-if="isTabFilled(tab.target)"
            class="tw-ml-2 tw-h-3.5 tw-w-3.5 tw-rounded-full tw-bg-primary tw-text-white tw-text-xs tw-inline-block tw-align-baseline"
          >
            {{ isTabFilled(tab.target) }}
          </div>
        </template>

        <template v-for="tab in tabs" :slot="tab.target">
          <FormulateForm
            :key="`${tab.target}_${searchKey}`"
            v-model="filters[tab.target]"
            :name="tab.target"
            debounce
          >
            <component
              :is="`property-search-${tab.target}`"
              :ref="`property-search-${tab.target}`"
              @selectMyself="selectMyself(tab.target)"
              @mapSelectedCities="setCitiesOnGeneral"
              @mapDrawn="updateCoordinates"
              @citySelected="setCitiesOnMap"
            />
            <div class="tw-my-4 tw-grid sm:tw-grid-flow-col sm:tw-max-w-sm tw-gap-x-6 tw-gap-y-4 tw-text-base tw-text-center">
              <router-link
                :to="{ query: { filters: JSON.stringify(filters) } }"
                class="tw-px-6 tw-py-2 !tw-text-white !tw-no-underline tw-bg-success hover:tw-bg-darken-success tw-rounded-md"
              >
                <i class="tw-mr-2 far fa-search" /> Zoeken
              </router-link>
              <button
                type="button"
                class="tw-px-6 tw-py-2 tw-bg-danger hover:tw-bg-darken-danger tw-rounded-md tw-text-white"
                @click="clearFilters"
              >
                <i class="tw-mr-2 far fa-times" /> Filters wissen
              </button>
            </div>
          </FormulateForm>
        </template>
      </Tabs>
      <DataTable
        v-show="showDataTable"
        v-model="selectedRecords"
        :loading="loading"
        :headers="headers"
        :can-select="true"
        :infinite-scroll="true"
        :all-record-ids="allPropertyIds"
        :max-select-all-amount="MAX_SELECT_ALL_AMOUNT"
        v-bind="properties"
        @changedOrder="search"
        @infiniteLoad="infiniteLoad"
        class="tw-my-8 tw-relative"
      >
        <template #toolbar>
          <div class="tw-mb-2 tw-flex tw-justify-end">
            <button
              type="button"
              title="Pubactie registreren"
              :disabled="!selectedRecords.length"
              class="action tw-bg-success"
              @click="showPubActionModal"
            >
              <i class="fa fa-bullhorn" /> Pubactie registreren
            </button>
            <button
              type="button"
              title="Lijst"
              :disabled="!selectedRecords.length"
              class="action tw-bg-primary"
              @click="showListModal"
            >
              <i class="fa fa-print" /> Lijst
            </button>
          </div>
        </template>

        <template #item-reference="{ item }">
          <EntityHoverable :value="{ id: item.id, reference: item.reference, type: 'property' }" />
        </template>
        <template #item-address="{ item }">
          {{ item.street }} {{item.number}} {{ item.box }}
        </template>
        <template #item-city="{ item }">
          <span v-if="item.city">{{ item.city.zip_code }} {{ item.city.name }}</span>
          <span v-else>-</span>
        </template>
        <template #item-price="{ item }">
          <span v-if="item.price" v-text="currency(item.price.price)" />
          <span v-else>-</span>
        </template>
        <template #item-created_on="{ item }">
          <span v-text="formatDateForLocale(item.created_on, false)" />
        </template>
      </DataTable>
    </div>
    <PublicationActionCreateModal ref="pubActionModal" :properties="propertyListData" />
    <ListExportModal
      ref="listExportModal"
      :templateType="listTemplateType"
      :selectedRecords="selectedRecords"
      :title="'Pandenlijst afdrukken'"
      :defaultListName="'Lijst van panden'"
    />
  </div>
</template>

<script>
import merge from 'lodash/merge'

import PageHeader from '@/components/PageHeader.vue'
import Tabs from '@/components/Tabs.vue'
import DataTable from '@/components/DataTable.vue'
import EntityHoverable from '@/components/iam/EntityHoverable'
import PublicationActionCreateModal from '@/components/properties/PublicationActionCreateModal'
import ListExportModal from '@/components/iam/ListExportModal'

import PropertySearchGeneral from '@/components/properties/PropertySearchGeneral'
import PropertySearchIndeling from '@/components/properties/PropertySearchIndeling'
import PropertySearchComfort from '@/components/properties/PropertySearchComfort'
import PropertySearchCommercial from '@/components/properties/PropertySearchCommercial'
import PropertySearchDossier from '@/components/properties/PropertySearchDossier'
import PropertySearchInvestment from '@/components/properties/PropertySearchInvestment'
import PropertySearchMap from '@/components/properties/PropertySearchMap'

import { currency, formatDateForLocale } from '@/utils/helpers'
import { getProperties, getPropertyListDataByIds, getPropertyIds } from '@/services/properties'
import { successModal, errorModal } from '@/modalMessages'
import { mapGetters } from 'vuex'

export default {
  name: 'PropertySearch',
  components: {
    PageHeader,
    Tabs,
    DataTable,
    PropertySearchGeneral,
    PropertySearchIndeling,
    PropertySearchComfort,
    PropertySearchCommercial,
    PropertySearchDossier,
    PropertySearchInvestment,
    PropertySearchMap,
    EntityHoverable,
    PublicationActionCreateModal,
    ListExportModal
  },
  constants: {
    MAX_SELECT_ALL_AMOUNT: 400
  },
  data () {
    return {
      searchKey: 'init',
      loading: false,
      allPropertyIds: [],
      selectedRecords: [],
      showDataTable: false,
      // The object properties includes keys to all the props that are needed in the DataTable, hence, we can use v-bind directly for clean code
      properties: {
        count: null,
        next: null,
        previous: null,
        results: []
      },
      filters: {
        general: {},
        indeling: {},
        comfort: {},
        commercial: {},
        dossier: {},
        investment: {},
        map: {}
      },
      propertyListData: [],
      test: {},
      listTemplateType: 3, // Property list type
      propertyListPrintData: {}
    }
  },
  watch: {
    '$route.query': {
      immediate: true,
      handler (value) {
        if (!value.filters) {
          this.showDataTable = false
          return false
        }

        this.showDataTable = true
        const parsedValues = JSON.parse(value.filters)
        const mergedFilters = merge(
          {
            general: {},
            indeling: {},
            comfort: {},
            commercial: {},
            dossier: {},
            investment: {},
            map: {}
          },
          parsedValues
        )
        this.filters = Object.assign({}, this.filters, mergedFilters)
        this.search()
      }
    }
  },
  computed: {
    ...mapGetters('account', ['collaborator']),

    tabs () {
      return [
        { name: 'Algemeen', target: 'general' },
        { name: 'Indeling', target: 'indeling' },
        { name: 'Comfort', target: 'comfort' },
        { name: 'Bedrijfsvastgoed', target: 'commercial' },
        { name: 'Investeringsvastgoed', target: 'investment' },
        { name: 'Dossier', target: 'dossier' },
        { name: 'Kaart', target: 'map' }
      ]
    },
    headers () {
      return [
        { value: 'reference', text: 'Referentie', orderable: true },
        { value: 'status_display_nl', text: 'Status', orderable: true, orderByKey: 'status_name' },
        { value: 'address', text: 'Adres', orderable: true, orderByKey: 'property_address' },
        { value: 'city', text: 'Plaats', orderable: true, orderByKey: 'city_name' },
        { value: 'price', text: 'Prijs', orderable: true, orderByKey: 'property_price' },
        { value: 'created_on', text: 'Aangemaakt op', orderable: true }
      ]
    },
    checklistQuery () {
      const filters = localStorage.getItem('checklist_filters')
      return { filters }
    }
  },
  methods: {
    currency,
    formatDateForLocale,
    isTabFilled (formName) {
      const values = Object.values(this.filters[formName])
        .filter(value => {
          if (Array.isArray(value)) return value.length // To check if the value is an array and has items
          return value
        })
      return values.length
    },
    showListModal () {
      this.$refs.listExportModal.show()
    },
    showMap (target) {
      if (target !== 'map') return
      const geosearch = this.filters?.map?.geosearch || []
      this.$refs['property-search-map'][0].geoSearch = geosearch
      this.$refs['property-search-map'][0].show()
    },
    selectMyself (formName) {
      let collaborators = this.filters[formName].collaborator
      if (!collaborators) collaborators = []

      const alreadyAdded = collaborators.find(value => value.id === this.collaborator.id)
      return alreadyAdded ? false : collaborators.push(this.collaborator)
    },
    updateCoordinates (coordinates) {
      this.$set(this.filters.map, 'geosearch', coordinates)
    },
    setCitiesOnGeneral (cities) {
      this.$set(this.filters.map, 'zipcodes', cities)
      this.$set(this.filters.general, 'cities', cities)
    },
    setCitiesOnMap (cities) {
      this.$set(this.filters.map, 'zipcodes', cities)
      this.$nextTick(() => {
        this.$refs['property-search-map'][0].setCitiesOnMap(cities)
      })
    },
    clearFilters () {
      this.searchKey = Math.random() // Reset form and it's values
      this.allPropertyIds = []
      this.$set(this.filters, 'general', {})
      this.$set(this.filters, 'dossier', {})
      this.$set(this.filters, 'comfort', {})
      this.$set(this.filters, 'indeling', {})
      this.$set(this.filters, 'commercial', {})
      this.$set(this.filters, 'investment', {})
      this.$set(this.filters, 'map', {})
      this.$refs['property-search-map'][0].clear()
      this.$router.push({ query: {} }).catch(() => {})
      this.$nextTick(() => {
        this.$refs['property-search-map'][0].show()
      })

      successModal('Alle filters gewist.')
    },
    parseParams (params = {}) {
      // merge is used to clone and merge all objects into an empty object, to prevent call by sharing.
      const { general, indeling, comfort, commercial, dossier, investment, map } = this.filters
      const values = merge({}, general, indeling, comfort, commercial, dossier, investment, map, params)
      for (const key in values) if (values[key] === false) delete values[key]

      if (values.cities?.length) values.cities = values.cities.map(city => city.id)
      if (values.groups?.length) values.groups = values.groups.map(group => group.id)
      if (values.office?.length) values.office = values.office.map(office => office.id)
      if (values.collaborator?.length) values.collaborator = values.collaborator.map(collaborator => collaborator.id)
      if (values.geosearch?.length) values.geosearch = JSON.stringify(values.geosearch)
      delete values.zipcodes // Zipcodes are used only to indicate that map has a selection too.

      return values
    },
    async showPubActionModal () {
      try {
        this.loading = true
        const params = {
          ids: this.selectedRecords.join()
        }
        const response = await getPropertyListDataByIds(params)
        this.propertyListData = response?.data
        this.$refs.pubActionModal.show()
        return response
      } catch (error) {
        errorModal('Fout bij ophalen van resultaten, probeer het opnieuw.')
      } finally {
        this.loading = false
      }
    },
    async fetchProperties (payload) {
      try {
        const response = await getProperties(payload, 50)
        return response.data
      } catch (error) {
        errorModal('Fout bij ophalen van resultaten, probeer het opnieuw.')
      }
    },
    async fetchPropertyIds (payload) {
      try {
        const response = await getPropertyIds(payload)
        this.allPropertyIds = response.data
        return response
      } catch (error) {
        errorModal('Er is iets misgegaan bij het zoeken. Selecteer alle functionaliteit werkt mogelijk niet. Probeer het opnieuw.')
      }
    },
    async search (data = {}) {
      this.loading = true
      // params are sent only sent in case of ordering.
      const payload = { params: this.parseParams(data.params) }
      const properties = await this.fetchProperties(payload)
      this.properties = properties
      this.allPropertyIds = []
      if (properties.count < this.MAX_SELECT_ALL_AMOUNT) await this.fetchPropertyIds(payload)
      this.loading = false
      return properties
    },
    async infiniteLoad ($infinite, next) {
      try {
        if (!next) {
          // No more data and state is loaded
          if (this.properties.results.length) $infinite.loaded()
          return $infinite.complete()
        }
        const properties = await this.fetchProperties({ url: next })
        const results = [...this.properties.results, ...properties.results]
        this.properties = { ...properties, results }
        $infinite.loaded()
        return properties
      } catch (error) {
        $infinite.error()
        console.error(error)
      }
    }
  }
}
</script>
